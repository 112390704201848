/* CSS that came with react*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  .header {display: none;}
  body {
    height: 0px;
  }
}

@media screen and (max-width: 999px) {

  /* font-size and layout for mobile devices. 
  Essentially this will increase font size 
  for smaller res machines and also stack the boxes differently.*/
  html {
    font-size: 25px;
  }

  .box {
    padding-inline: 0.5rem;
  }

  .box,
  .timetable {
    grid-column: 1/end;
  }

  .calendar {
    grid-row: 2;
  }

  .quote {
    grid-row: 3;
  }

  .focus {
    grid-row: 4;
    grid-column: 1/end;
  }

  .notes {
    grid-row: 5;
    grid-column: 1/end;
  }
}

.grid_wrapper {
  /*should this even be a grid? A flexbox will still work but it won't be as responsive i think.*/
  display: grid;
}

/* HEADER */

.flex {
  display: flex;
  vertical-align: middle;
  justify-content: center;
}
.header {
  justify-content: left;
  height: 8vh;
}

.logo {
  max-inline-size: 4.5vh;
  padding: 1rem 1rem 1rem 1rem;
  margin-inline-end: 1rem;
  display: inline;
}

.navigation {
  top: 0;
  box-shadow: 0 4px 12px rgb(0 0 0 / 5%);
  position: sticky;
  z-index: 1;
}

header {
  font-size: 1.2rem; /* 1.5rem */
  background-color: white;
  z-index: 3;
  padding: 0;
  border-bottom: 0.1rem solid rgba(242,242,242,1);
}

ul.link_list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  list-style-position: inside;
  padding-inline-start: 0rem !important;
}

.drop_down_logo {
  padding-inline-end: 1rem;
  cursor: pointer;
}

.link_list li{
  text-align: none;
  list-style-type: none;
  display: inline;
  padding-inline-end: 2rem;
}

.drop_down_header {
  display: block;
}

.drop_down_header span {
  display: none;
}


/* || TIMETABLE*/

.box {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgb(0 0 0 / 5%);
  /*
  transition-property: box-shadow, transform;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(.16,1,.29,.99);
  */
}

.timetable_countdown {
  margin: auto;
}


/*New Styles*/
.center_vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timetable {
  font-size: 1.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding-block-start: 1rem;
  grid-column: 1/4;
  grid-row: 1;
  text-align: center;
}

.timetable_countdown {
  margin-block-end: 2rem;
}

.timetable_today {
  max-width: 1000px;
  width: 100%;
  margin: auto;
  table-layout: fixed;
  border-collapse: separate;
  padding: 1.5rem;
  text-align: left;
  border-spacing: 0 5px;
}

.timetable_today thead {
  color: rgba(124, 124, 124, 0.8);
}

.timetable_today thead td {
  border-bottom: 0.15rem solid rgba(124, 124, 124, 0.3);
  padding: 0.7rem;
  text-transform: uppercase;
}

.timetable tbody td {
  padding: 0.5rem;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: color 0.1s ease-out;
}

.timetable tbody td div {
  font-size: 1rem;
  color: rgba(124, 124, 124, 0.8)
}


.timetable tbody tr.period_class {
  background-color: rgba(250, 250, 250, 1);
}

.timetable .period_break {
  color: rgba(124, 124, 124, 0.8);
  transition: all 0.2s ease-out;
  background-color: rgba(242, 242, 242, 0.7);
}

.timetable_today tbody tr.period_class:hover td {
  transition: background-color 0.3s ease-in;
  background-color: rgba(209, 231, 246, 0.7);

}

.timetable_today tbody tr.period_class {
  animation: table_slide 0.3s ease-in;
}

.timetable_today td:nth-child(2) {
  padding-inline-end: 1rem;
  text-align: right;
  width: 30%;
}

.timetable_today td:nth-child(1) {
  text-align: right;
  width: 30%;
}

.timetable_today td:first-child {
  padding-inline-start: 1rem;
  text-align: left;
  width: 70%;
}

/* || TIMETABLE WEEKLY */

.week thead {
  color: rgba(124, 124, 124, 0.8);
  text-transform: uppercase;
}
.week {
  margin: auto;
  width: 50%;
  margin-block-end: 3rem;
}
.week thead td {
  border-bottom: 2px solid rgb(124,124,124);
}

.week tbody .day .period_cycle_class td{
  cursor: pointer;
  transition: all 0.2s ease-out;
  background-color: white;
  width: 4rem;
  height: 2rem;

}
.week tbody .day .period_cycle_empty td{
  cursor: pointer;
  transition: all 0.2s ease-out;
  background-color: rgb(247, 247, 247);
  width: 4rem;
  height: 2rem;

}

.week tbody .day td.hovered_class {
  background-color: #1D6295;
  color: white;
  transition: all 0.5s ease-in;
}

/* || TIMETABLE PRINT */

.print_button {
  text-align: center;
  background-color: white;
  border: 2px solid rgb(124,124,124);
  cursor: pointer;
  width: 10rem;
  height: 4rem;
  
}